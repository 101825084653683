const truncate = (str = '', max, suffix) => str.length < max ? str : `${str.substr(0, str.substr(0, max - suffix.length).lastIndexOf(' '))}${suffix}`

export const metaTitle = (title = '', category = '') => {
  const siteName = 'Assurimo'
  const separator = '–'

  return `${title}${category ? ` ${separator} ${category}` : ''} ${separator} ${siteName}`
}

export const metaDescription = (description) => truncate(description, 150, '...')
