import React from 'react'
import PropTypes from 'prop-types'

const AboutUsReinsuranceItem = ({
  title = ''
}) => {
  return (
    <li className='AboutUsReinsuranceItem'>{ title }</li>
  )
}

AboutUsReinsuranceItem.propTypes = {
  title: PropTypes.string
}

export default AboutUsReinsuranceItem
