import React from 'react'
import PropTypes from 'prop-types'

import Link from 'components/Link'

const AboutUsPartnersItem = ({
  logo = '',
  link = ''
}) => {
  return (
    <li className='AboutUsPartnersItem'>
      <Link target='_blank' to={ link } rel='noreferrer'>
        <img src={ logo } />
      </Link>
    </li>
  )
}

AboutUsPartnersItem.propTypes = {
  logo: PropTypes.string,
  link: PropTypes.string
}

export default AboutUsPartnersItem
