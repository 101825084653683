import React from 'react'
import PropTypes from 'prop-types'
import { useIntl } from 'react-intl'
import Button from 'components/Button'
import innerHTML from '../utils/innerHTML'

const AboutUsOffersNumbersItem = ({
  value = 0,
  title = '',
  image,
  imageWidth = '20rem',
  ctaLink
}) => {
  const intl = useIntl()

  return (
    <li className='AboutUsOffersNumbersItem'>
      <img className='AboutUsOffersNumbersItem-image' src={ image } style={ { width: imageWidth } } />
      <div className='AboutUsOffersNumbersItem-numberValue'>{ value }</div>
      <div className='AboutUsOffersNumbersItem-title' { ...innerHTML(title) } />
      <Button style='transparentDarkBlue' to={ ctaLink }>{ intl.formatMessage({ id: 'cta.discover' }) }</Button>
    </li>
  )
}

AboutUsOffersNumbersItem.propTypes = {
  value: PropTypes.number,
  title: PropTypes.string,
  image: PropTypes.string,
  imageWidth: PropTypes.string,
  ctaLink: PropTypes.string
}

export default AboutUsOffersNumbersItem
